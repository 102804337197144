<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                    lg="8"
                    class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"/>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                    lg="4"
                    class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                        sm="8"
                        md="6"
                        lg="12"
                        class="px-xl-2 mx-auto"
                >
                    <b-card-title
                            title-tag="h2"
                            class="font-weight-bold mb-1"
                    >
                        Forgot your Password for Cineself?
                    </b-card-title>
                    <b-card-text class="mb-2">
                        You can recover your password by entering yor E-mail below!
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                                class="auth-login-form mt-2"
                                @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                    label="Email"
                                    label-for="login-email"
                            >
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Email"
                                        rules="required|email"
                                >
                                    <b-form-input
                                            id="login-email"
                                            v-model="form.email"
                                            :state="errors.length > 0 ? false:null"
                                            name="login-email"
                                            placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit and back buttons -->
                            <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                                    @click="requestPass"
                            >
                                Recover Password
                            </b-button>
                            <b-button
                                    type="submit"
                                    variant="outline-primary"
                                    block
                                    @click="goBack()"
                            >
                                Back
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import VuexyLogo from '@core/layouts/components/Logo.vue';
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BCardText,
        BCardTitle,
        BForm,
        BButton,
    } from 'bootstrap-vue';
    import { required, email } from '@validations';
    import { togglePasswordVisibility } from '@core/mixins/ui/forms';
    import store from '@/store/index';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import Parse from 'parse';

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BCardText,
            BCardTitle,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                form: {
                        email: "",
                },
                loading: false,
                status: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email,
            }
        },
        computed: {
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        methods: {
            goBack(){
                this.$router.push({name:"login"});
            },
            requestPass(){
                let userEmail = this.form.email;
                let self = this;
                if (userEmail){
                    Parse.User.requestPasswordReset(userEmail).then(function() {
                        self.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Password reset e-mail successfully sent to "+userEmail,
                                icon: 'CheckIcon',
                                variant: 'primary',
                            },
                        });
                        self.$router.push({name: "BackToLogin"})
                    }).catch(function(error) {
                        self.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error code: " + error.code + " " + error.message,
                                icon: 'XIcon',
                                variant: 'danger',
                            },
                        });
                    });
                }
            },
            validationForm() {
                let self = this;
                this.$refs.loginValidation.validate().then(success => {
                    if (success && self.validatePasses()) {
                        let user = new Parse.User();
                        user.set("username", self.form.email);
                        user.set("password", self.form.password);
                        user.set("email", self.form.email);
                        user.set("firstName", self.form.firstName);
                        user.set("lastName", self.form.lastName);
                        user.set("userType", 'producer');

                        user.signUp().then(function(){
                            //self.setDefaults(resp.id);
                            self.login();
                        }).catch(function(error){
                            self.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Error code: " + error.code + " " + error.message,
                                    icon: 'CheckIcon',
                                    variant: 'danger',
                                },
                            });
                        });
                    }else{
                        console.log("Unsuccessful form validation");
                    }
                }).catch(err=> {
                    console.log("Unsuccessful form validation", err.message);
                })
            },
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
